import { Controller } from "stimulus";

export default class extends Controller {
  static values = { text: String };

  connect() {
    this.element.dataset["action"] = "click->disable-with#disableForm";
  }

  disableForm(_evt) {
    if (this.textValue) {
      this.updateSavingText(this.element);
    }
  }

  updateSavingText(element) {
    if (element.textContent) {
      element.textContent = this.textValue;
    } else if (element.type === "submit") {
      element.value = this.textValue;
    }
  }
}
