import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "input", "categoryButton", "categoryPrompt"];

  openModal() {
    this.containerTarget.classList.remove("hidden");
  }

  closeModal() {
    this.containerTarget.classList.add("hidden");
  }

  onCategorySelect(event) {
    this.inputTarget.value = event.target.dataset.categoryId;
    this.categoryButtonTarget.innerText = event.target.innerText;
    this.categoryPromptTarget.innerText = event.target.dataset.prompt;
    this.closeModal();
  }
}
