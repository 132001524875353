import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "previewContainer"];

  show(event) {
    this.resizeImageToViewport(event);
    this.modalTarget.classList.remove("hidden");
  }

  hide() {
    this.modalTarget.classList.add("hidden");
    this.previewContainerTarget.innerHTML = "";
  }

  resizeImageToViewport(event) {
    const img = document.createElement("img");
    img.src = event.target.src;
    img.classList.add("modal--image");
    this.previewContainerTarget.appendChild(img);
  }
}
