import { Controller } from "stimulus";

export default class extends Controller {
  submit() {
    const formData = new FormData(this.element);

    var jsonBody = {};
    formData.forEach((value, key) => (jsonBody[key] = value));

    const method = formData.get("_method").toUpperCase();

    fetch(this.element.action, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
      },
      method: method,
      body: JSON.stringify(jsonBody),
    });
  }
}
