import { Controller } from "stimulus";

export default class extends Controller {
  static values = { interval: Number, url: String, active: Boolean };

  connect() {
    if (this.activeValue) {
      this.interval = setInterval(() => this.poll(), this.intervalValue);
    }
  }

  disconnect() {
    clearInterval(this.interval);
    this.interval = null;
  }

  poll() {
    window.Turbo.visit(this.urlValue);
  }
}
