import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["previewContainer"];

  onFileInputChange(event) {
    const file = event.target.files[0];

    if (file.type.match("image/*")) {
      this.addImagePreview(file);
    } else {
      this.addLinkPreview(file);
    }
  }

  addImagePreview(file) {
    const url = URL.createObjectURL(file);

    let image = document.createElement("img");
    image.src = url;
    image.classList.add("w100");

    image.dataset.action = "click->image-modal#show";

    this.previewContainerTarget.replaceChildren(image);
  }

  addLinkPreview(file) {
    const url = URL.createObjectURL(file);

    const html = `
      <a href=${url} target="_blank" class="no-text-decoration fnt-size-sm color--ash-grey">
        <div class="expense__receipt-img mb-0-5"></div>
        <p class="expense__filename fnt-size-sm text-center mb-0-5">${this.sanitizeText(
          file.name
        )}</p>
      </a>
      `;

    this.previewContainerTarget.innerHTML = html;
  }

  sanitizeText(text) {
    var element = document.createElement("div");
    element.innerText = text;
    return element.innerHTML;
  }
}
