import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["editableInput", "editableBtn", "saveBtn"];
  static values = { state: String };

  edit() {
    this.editableInputTarget.removeAttribute("disabled");
    this.editableInputTarget.focus();
    this.editableBtnTarget.classList.add("hidden");
    this.saveBtnTarget.classList.remove("hidden");
  }
}
