import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.resizeEvent = window.addEventListener("resize", () =>
      this.resizeToViewportHeight()
    );

    this.resizeToViewportHeight();
  }

  disconnect() {
    window.removeEventListener("resize", this.resizeEvent);
  }

  resizeToViewportHeight() {
    this.element.style.height = `${window.innerHeight}px`;
  }
}
